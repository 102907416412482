@import '~app/mixins';

.launchControl {
  color: #666;
  font-size: 14px;
  text-align: center;

  .header {
    font-size: 18px;
    font-weight: bold;
  }

  .body {
    margin: 10px 40px 30px;
  }

  .footer button + button {
    margin-left: 30px;
  }
}
